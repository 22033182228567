
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";
import Faq from "../components/Faq";
import CallToAction from "../components/Call-to-action";

const FAQ = () => {
  return (
    <>
    <section className='faqs-section py-5'>
        <div className='container'>
        <div className='text-center mb-5'>
            <h3 className='mb-0 title-text'><span className='branding-2 gradient-class font-weight-bold'>Answers to Your Burning Questions</span></h3>
            <h3 className='mb-3 title-text'>Frequently Asked Questions about Hawk Glide</h3>
        </div>
        <div className='accordian-react-data'>
            <div><Faq /></div>
        </div>
        </div>
      </section>
     <CallToAction />
    </>
  );
};

export default FAQ; 