// Pricing.js

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Rating from "react-rating-stars-component";
import Faq from "../components/Faq";
import secureLocalStorage from "react-secure-storage";
import { getAllPlans } from "../data/actionMethods";
import Checkout from "./checkout";
import CallToAction from "../components/Call-to-action";

const Pricing = () => {
  const [activeTab, setActiveTab] = useState("monthly");
  const [planData, setPlanData] = useState([]);
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getAllPlans().then((res) => {
      // console.log('');
      setPlanData(res.data.content ? res.data.content : []);
      console.log("All Plan Data=====", res.data.content);
    });
  }, []);

  useEffect(() => {
    console.log("Updated Cart top:", cart);
    // Save cart data to localStorage whenever it changes
    secureLocalStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (selectedPlan) => {
    setCart((prevCart) => [...prevCart, selectedPlan]);
  };

  const handleGetStarted = (selectedPlan) => {
    console.log("selectedPlan", selectedPlan);
    addToCart(selectedPlan);
    console.log("Updated Cart:", cart);
    // Navigate to the checkout page with the cart data
    navigate("/signup", { state: { cart: [...cart, selectedPlan] } });
  };

  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="about-us-title pb-4">
            <h1 className="title-text branding-1 text-center">
              See immediate results
              <br />
              <span className="gradient-class font-weight-bold gradient-class">
                Pricing that justifies the work.
              </span>
            </h1>
            <p className="font-22 text-center my-3 font-22">
              Whether you are a startup, freelancer, or influencer, it's for
              you!
            </p>
            <div className="text-center">
              <img
                src={require("../images/down-arrow.png")}
                style={{ marginTop: "10px", marginBottom: 10 }}
              />
            </div>
          </div>

          <div className="tabs pb-3">
            <div className="d-flex justify-content-center">
              <button
                style={{ border: "none", padding: "10px 40px" }}
                className={`animated-button-2 btn-outline-primary mx-2 ${
                  activeTab === "monthly" ? "active animated-button" : ""
                }`}
                onClick={() => handleTabChange("monthly")}
              >
                <span>Monthly</span>
              </button>
              <button
                style={{ border: "none", padding: "10px 40px" }}
                className={`animated-button-2  btn-outline-primary mx-2 ${
                  activeTab === "half-yearly" ? "active animated-button" : ""
                }`}
                onClick={() => handleTabChange("half-yearly")}
              >
                <span>Half Yearly</span>
              </button>
              <button
                style={{ border: "none", padding: "10px 40px" }}
                className={`animated-button-2  btn-outline-primary mx-2 ${
                  activeTab === "yearly" ? "active animated-button" : ""
                }`}
                onClick={() => handleTabChange("yearly")}
              >
                <span>Yearly</span>
              </button>
            </div>
            <p className="text-center pt-4">
              Try 100% free for 1 month. Cancel Anytime. No Credit Card Needed.
            </p>
            <div className="tab-content mt-2">
              <div
                className={`tab-pane show ${
                  activeTab === "monthly" ? "active" : ""
                }`}
                id="monthly"
              >
                <div className="row my-5">
                  {planData.map((plan , index) => (
                    <>
                      {plan.planTypeID === 2 && (
                        <div className="col-md-4 px-3">
                          <div className={`section-gray py-5 px-5 ${index === 1 ? 'most-popular' : ''}`}>
                            <p className="font-22 font-weight-bold">
                              {plan.planName}
                            </p>
                            <p>
                              {plan && plan.planMeta
                                ? JSON.parse(plan.planMeta)
                                    .planDescription
                                : ""}
                            </p>
                            <p>
                              <span
                                className="gradient-class font-weight-bold fs-3 position-relative"
                                style={{ paddingLeft: 10 }}
                              >
                                <span
                                  className="fs-6"
                                  style={{
                                    position: "absolute",
                                    left: 0,
                                    top: "10%",
                                  }}
                                >
                                  ₹
                                </span>
                                {plan.planPrice.toLocaleString()}
                              </span>
                              /
                              {plan.planTypeID === 2
                                ? "month"
                                : plan.planTypeID === 4
                                ? "year"
                                : ""}
                            </p>
                            <div
                              className="border-line my-4"
                              style={{
                                height: 2,
                                backgroundColor: "rgb(236, 236, 236)",
                                width: "100%",
                              }}
                            ></div>
                            <ul className="listing-items">
                              {plan && plan.planMeta
                                ? JSON.parse(plan.planMeta)
                                    .planFeatures.split(/\n/)
                                    .map((feature, index) => (
                                      <li key={index}>{feature}</li>
                                    ))
                                : null}
                            </ul>

                            <div className="links-tab py-2 my-4 mb-0 text-center">
                              {/* <Link
                        to={"/signup"}
                        className="animated-button font-weight-bold text-white"
                      >
                        Get Started
                      </Link> */}
                              <button
                                className="animated-button font-weight-bold text-white"
                                onClick={() => handleGetStarted(plan)}
                              >
                                Get Started
                              </button>
                              <p className="mt-4 mb-0" style={{ fontSize: 16 }}>
                                Signup for 30 Days Free Trial
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
                <div
                  className={`tab-pane ${
                    activeTab === "half-yearly" ? "active" : ""
                  }`}
                  id="half-yearly"
                >
                  <div className="row my-5">
                    {planData.map((plan , index) => (
                      
                      <>
                        {plan.planTypeID === 3 && (
                          <div className="col-md-4 px-3" key={index}>
                           
                            <div className={`section-gray py-5 px-5 ${index === 1 ? 'most-popular' : ''}`}>
                              <p className="font-22 font-weight-bold">
                                {plan.planName}
                              </p>
                              <p>
                                {plan && plan.planMeta
                                  ? JSON.parse(plan.planMeta)
                                      .planDescription
                                  : ""}
                              </p>
                              <p>
                                <span
                                  className="gradient-class font-weight-bold fs-3 position-relative"
                                  style={{ paddingLeft: 10 }}
                                >
                                  <span
                                    className="fs-6"
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      top: "10%",
                                    }}
                                  >
                                    ₹
                                  </span>
                                  {plan.planPrice.toLocaleString()}
                                </span>
                                /
                                {plan.planTypeID === 2
                                  ? "month"
                                  : plan.planTypeID === 4
                                  ? "year"
                                  : ""}
                              </p>
                              <div
                                className="border-line my-4"
                                style={{
                                  height: 2,
                                  backgroundColor: "rgb(236, 236, 236)",
                                  width: "100%",
                                }}
                              ></div>
                              <ul className="listing-items">
                                {plan && plan.planMeta
                                  ? JSON.parse(plan.planMeta)
                                      .planFeatures.split(/\n/)
                                      .map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                      ))
                                  : null}
                              </ul>

                              <div className="links-tab py-2 my-4 mb-0 text-center">
                                {/* <Link
                       to={"/signup"}
                       className="animated-button font-weight-bold text-white"
                     >
                       Get Started
                     </Link> */}
                                <button
                                  className="animated-button font-weight-bold text-white"
                                  onClick={() => handleGetStarted(plan)}
                                >
                                  Get Started
                                </button>
                                <p
                                  className="mt-4 mb-0"
                                  style={{ fontSize: 16 }}
                                >
                                  Signup for 30 Days Free Trial
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "yearly" ? "active" : ""
                  }`}
                  id="yearly"
                >
                  <div className="row my-5">
                    {planData.map((plan , index) => (
                      <>
                        {plan.planTypeID === 4 ? (
                          <div className="col-md-4 px-3">
                            <div className={`section-gray py-5 px-5 ${index === 1 ? 'most-popular' : ''}`}>
                              <p className="font-22 font-weight-bold">
                                {plan.planName}
                              </p>
                              <p>
                                {plan && plan.planMeta
                                  ? JSON.parse(plan.planMeta)
                                      .planDescription
                                  : ""}
                              </p>
                              <p>
                                <span
                                  className="gradient-class font-weight-bold fs-3 position-relative"
                                  style={{ paddingLeft: 10 }}
                                >
                                  <span
                                    className="fs-6"
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      top: "10%",
                                    }}
                                  >
                                    ₹
                                  </span>
                                  {plan.planPrice.toLocaleString()}
                                </span>
                                /
                                {plan.planTypeID === 2
                                  ? "month"
                                  : plan.planTypeID === 4
                                  ? "year"
                                  : ""}
                              </p>
                              <div
                                className="border-line my-4"
                                style={{
                                  height: 2,
                                  backgroundColor: "rgb(236, 236, 236)",
                                  width: "100%",
                                }}
                              ></div>
                              <ul className="listing-items">
                                {plan && plan.planMeta
                                  ? JSON.parse(plan.planMeta)
                                      .planFeatures.split(/\n/)
                                      .map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                      ))
                                  : null}
                              </ul>

                              <div className="links-tab py-2 my-4 mb-0 text-center">
                                {/* <Link
                        to={"/signup"}
                        className="animated-button font-weight-bold text-white"
                      >
                        Get Started
                      </Link> */}
                                <button
                                  className="animated-button font-weight-bold text-white"
                                  onClick={() => handleGetStarted(plan)}
                                >
                                  Get Started
                                </button>
                                <p
                                  className="mt-4 mb-0"
                                  style={{ fontSize: 16 }}
                                >
                                  Signup for 30 Days Free Trial
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : ( 
                          <>
                          {plan.length === 0 && (
                            <div className="col-md-12">
                              <p className="text-center">No Package Found</p>
                            </div>
                          )}
                        </>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        
      </section>

      {/* <section className="pt-4 pb-4">
          <div className="row">
            {planData.map((plan) => (
              <div className="col-md-4" key={plan.razorpayPlan.id}>
                
                <div className="card">
                  <div className="card-body text-center">
                    <h5 className="card-title">{plan.planName}</h5>
                    
                    <p className="card-text">Price: {plan.planPrice.toLocaleString() / 100}</p>
                    
                    <div className="links-tab py-2 my-4 mb-0 text-center">
                      <button
                        className="animated-button font-weight-bold text-white"
                        onClick={() => handleGetStarted(plan)}
                      >
                        Get Started
                      </button>
                      <p className="mt-4 mb-0" style={{fontSize:16}}>Signup for 30 Days Free Trial</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section> */}

      <div className="container">
        <section className="call-action-dark pt-4 pb-4 bg-blue-dark rounded-4">
          <div className="row p-5 py-4">
            <div className="col-md-6">
              <h2 className="fs-1 text-white title-text">
                <span className="font-weight-bold gradient-class gradient-class">
                  Calling all Agencies!
                </span>
              </h2>
              <p className="font-22 text-white fs-5">
                Hawk Glide is your ultimate solution for efficient client
                management.
              </p>
            </div>
            <div className="col-md-6">
              <div
                className="links-tab py-3 my-4 mb-0 text-right"
                style={{ float: "right" }}
              >
                <Link
                  to={"/pricing"}
                  className="animated-button-2"
                  style={{
                    display: "block",
                    textAlign: "center",
                    minWidth: 200,
                  }}
                >
                  <span>See Pricing</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="faqs-section py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h3 className="mb-0 title-text">
              <span className="gradient-class gradient-class font-weight-bold">
                Answers to Your Burning Questions
              </span>
            </h3>
            <h3 className="mb-3 title-text">
              Frequently Asked Questions about Hawk Glide
            </h3>
          </div>
          <div className="accordian-react-data">
            <div>
              <Faq />
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </>
  );
};

export default Pricing;
