// src/Home.js
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../style.css";
import Rating from "react-rating-stars-component";
import Faq from "../components/Faq";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WingsImg from "../images/HG-WingsBG.svg";
import IconTop from "../images/icon01.svg";
import IconMid from "../images/icon04.svg";
import IconLeft from "../images/icon03.svg";
import IconRight from "../images/icon02.svg";

const StarRating = ({ rating }) => {
  return (
    <Rating
      count={5}
      value={rating}
      size={24}
      activeColor="#ffd700"
      isHalf={true}
      edit={false}
    />
  );
};

const Home = () => {
  const containerRef = useRef(null);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const { offsetWidth, offsetHeight } = containerRef.current;

    const x = (clientX / offsetWidth - 0.5) * 30; // Adjust the sensitivity
    const y = (clientY / offsetHeight - 0.5) * 30; // Adjust the sensitivity

    setRotation({ x, y });
  };

  const handleMouseLeave = () => {
    setRotation({ x: 0, y: 0 });
  };

  const bannerItemStyle = {
    transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
    transition: "transform 0.3s ease", // Adjust the transition duration and easing
  };

  /* stikcy scroll */

  const featureRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold based on your needs
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    featureRefs.current.forEach((ref) => {
      observer.observe(ref);
    });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  // const containerRef = useRef(null);

  // const handleMouseMove = (e) => {
  //   if (containerRef.current) {
  //     const container = containerRef.current;
  //     const { left, top, width, height } = container.getBoundingClientRect();

  //     const mouseX = e.pageX - (left + window.pageXOffset - container.scrollLeft);
  //     const mouseY = e.pageY - (top + window.pageYOffset - container.scrollTop);

  //     const rotateX = (mouseY / height - 0.5) * 20; // Adjust the rotation sensitivity
  //     const rotateY = (mouseX / width - 0.5) * 20; // Adjust the rotation sensitivity

  //     container.style.transform = `perspective(5000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  //   }
  // };

  // const handleMouseLeave = () => {
  //   if (containerRef.current) {
  //     containerRef.current.style.transform = 'perspective(5000px) rotate(0)';
  //   }
  // };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true, // Enable fade effect
    autoplay: true,
    autoplaySpeed: 2000, // Set autoplay speed in milliseconds
  };

  return (
    <div className="home">
      <section className="hero-section-bar py-5">
        <div className="container">
          <div className="row align-items-center">
            {/* Text on the left side */}
            <div className="col-md-12 text-center my-5 pt-5">
              <div className="text-container">
                {/* <Link to={"/"} className="navbar-brand">
                  <img
                    className="logo-brands mb-5"
                    src={require("../images/hawk.png")}
                    alt="Your Logo"
                  />
                </Link> */}
                <div className="w-63 m-auto">
                  <h1 className="py-2  gradient-class">
                    Transform your{" "}
                    <span className="branding-2 ">Social Media Strategy</span>{" "}
                    with Hawk Glide
                  </h1>
                </div>
                <div className="space-small"></div>
                <div className="w-52 m-auto">
                  <h2 className="py-2">
                    AI-Powered Banner Creation, Content Generation, and Seamless
                    Posting!
                  </h2>
                </div>
                <div className="space-small"></div>

                <div className="links-tab py-2">
                  <Link to={"/pricing"} className="animated-button ">
                    Start your 30-Day Free Trial
                    <img
                      style={{ width: "1.2em", marginLeft: 5, marginTop: -4 }}
                      className="hawk-white"
                      src={require("../images/hawk-white.png")}
                      alt="white logo"
                    />
                  </Link>
                  <p className="pt-2"><span className="gradient-class">30 Days Free Trial.</span> No Credit Card Needed.</p>
                  {/* <Link
                    to={"/"}
                    className="animated-button-2"
                    style={{ marginLeft: 15 }}
                  >
                    Watch Video
                  </Link> */}
                </div>
                {/* Add any other text or components as needed */}
              </div>
            </div>

            {/* Image on the right side */}
            {/* <div className="col-md-6 text-center">
                <div className="image-container">
                <img
                    src={require('../images/top-banner-right.jpg')}
                    alt="Hero Banner Image"
                    className="img-fluid"
                />
                </div>
            </div> */}
          </div>

          <div className="services-title text-center">
            <p className="fs-2 gradient-class font-weight-bold my-2">
              Join Over 1,000 Forward-Thinking Business Owners
            </p>
            <p className="my-1 text-black">
              Who've Transformed their Social Media
            </p>
            <p className="text-black">
              Presence in <strong>Just 180 Days</strong> with Hawk Glide!
            </p>
          </div>
          <div className="row pt-4" style={{ justifyContent: "center" }}>
            <div className="brands-list">
              <div className="branding-logo">
                <img
                  src={require("../images/seccret-room.png")}
                  alt="Product 1"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="branding-logo">
                <img
                  src={require("../images/shubamMotivala.png")}
                  alt="Product 2"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>

              <div className="branding-logo">
                <img
                  src={require("../images/ohstree.png")}
                  alt="Product 3"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="branding-logo">
                <img
                  src={require("../images/seccret-room.png")}
                  alt="Product 1"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="branding-logo">
                <img
                  src={require("../images/shubamMotivala.png")}
                  alt="Product 2"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>

              <div className="branding-logo">
                <img
                  src={require("../images/ohstree.png")}
                  alt="Product 3"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="showcase-section purple-bg py-5">
        <div className="container">
          <div class="showcase-content w-63 m-auto text-center">
            <h4 class="text-white fs-3">
              Generate creatives that help you sell. Fast.
            </h4>
            <h3 className="title-text fs-1 py-2 mb-3 gradient2-class font-weight-bold">
              Hawk Glide is emerging as one of the fastest-growing Artificial
              Intelligence social media management platforms in 2024!
            </h3>
            <div
              class="bannerWrap"
              ref={containerRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <Slider {...settings}>
                <div class="banner-item">
                  <div class="banner-item-rotate" style={bannerItemStyle}>
                    <div class="banner-images"></div>
                    <img
                      className="image-small flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-medium flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-big flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-medium is-right flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-small is-right flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                  </div>
                </div>

                <div class="banner-item">
                  <div class="banner-item-rotate">
                    <div class="banner-images"></div>
                    <img
                      className="image-small flip"
                      src={require("../images/banners/banner-2.png")}
                      alt=""
                    />
                    <img
                      className="image-medium flip"
                      src={require("../images/banners/banner-2.png")}
                      alt=""
                    />
                    <img
                      className="image-big flip"
                      src={require("../images/banners/banner-2.png")}
                      alt=""
                    />
                    <img
                      className="image-medium is-right flip"
                      src={require("../images/banners/banner-2.png")}
                      alt=""
                    />
                    <img
                      className="image-small is-right flip"
                      src={require("../images/banners/banner-2.png")}
                      alt=""
                    />
                  </div>
                </div>

                <div class="banner-item">
                  <div class="banner-item-rotate">
                    <div class="banner-images"></div>
                    <img
                      className="image-small flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-medium flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-big flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-medium is-right flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                    <img
                      className="image-small is-right flip"
                      src={require("../images/banners/banner-1.png")}
                      alt=""
                    />
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div class="showcase-btm-ctn mt-5 m-auto text-center bg-white">
            <img className="" src={IconTop} alt="" style={{ width: "140px" }} />
            <h3 className="branding-1 title-text mt-3 mb-4 branding-2 gradient-class font-weight-bold">
              Boost Your Social Media Engagement for Fast Growth with Our
              AI-Powered Tool
            </h3>
            <p class="fs-4">
              In 2024, let AI optimize every aspect of your social media
              marketing. Hawk Glide brings data-backed, results-driven ad
              creatives within reach.
            </p>
          </div>

          <div class="row showcase-btm-box align-items-center w-70 m-auto mt-5 mb-5 pt-5 pb-5">
            <div className="col-md-5">
              <div class="showcase-btm-left gradient2-bg text-white">
                <div class="text-end">
                  <img className="" src={IconLeft} alt="" />
                </div>
                <h3 style={{ marginTop: "-50px" }}>Up to 100x</h3>
                <h5>Higher Conversion Rates</h5>
                <p class="text-white pe-5">
                  Compared to creatives that are not data-backed.
                </p>
              </div>
            </div>
            <div className="col-md-2 text-center">
              <img
                className=""
                src={IconMid}
                alt=""
                style={{ width: "140px" }}
              />
            </div>
            <div className="col-md-5">
              <div class="showcase-btm-right gradient3-bg text-white">
                <div class="text-end">
                  <img className="" src={IconRight} alt="" />
                </div>
                <h3 style={{ marginTop: "-50px" }}>Up to 100x</h3>
                <h5>Higher Conversion Rates</h5>
                <p class="text-white pe-5">
                  Compared to creatives that are not data-backed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <img className="" src={WingsImg} alt="" /> */}

      {/* <section className="service-section py-5 section-gray">
        <div className="container">
          
        </div>
      </section> */}

      <section className="content-section scroll-data py-5">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-5 sticky-heading">
              <h3 className="branding-1 title-text mb-3">
                <span className="branding-2 gradient-class ">
                  Why Hawk Glide?
                </span>
              </h3>
              <p className="font-22 mb-5">
                Hawk Glide is your ticket to effortless marketing success. Our
                smart AI technology takes the stress out of social media
                marketing, saving you time and money. It helps you connect with
                more people and engage them effectively. With Hawk Glide, you'll
                breeze through your marketing tasks, reaching your audience
                effortlessly and achieving your goals with ease.
              </p>
              <Link to={"/pricing"} className="animated-button">
                Start Gliding for Free
              </Link>
              <p className="pt-2"><span className="gradient-class">30 Days Free Trial.</span> No Credit Card Needed.</p>
            </div>
            <div className="col-md-7 pt-5 pb-5" style={{overflow : 'hidden'}}>
              <div className="sticky-content_informations is-home">
                <div
                  className="sticky-content__feature is-is"
                  ref={(el) => featureRefs.current.push(el)}
                >
                  {/* Content for the first feature */}
                  <div className="sticky-content__feature-icon-wrapper">
                    <div className="logo-white-wrapperr is-red with-shadow">
                      {/* <div className="login-text hidden-mobile">Login</div> */}
                      <img
                        src={require("../images/hashtag.png")} style={{width : '100%'}}
                      />
                    </div>
                  </div>

                  <h3 className="gradient-class">Generate Hashtags</h3>
                  <p class="paragraph">
                    Boost your social media visibility effortlessly with Hawk
                    Glide's AI-powered hashtag generation tool. Get trending and
                    relevant hashtags tailored to your posts, expanding your
                    reach and audience engagement.
                  </p>
                </div>
                <div
                  className="sticky-content__feature is-is"
                  ref={(el) => featureRefs.current.push(el)}
                >
                  {/* Content for the second feature */}
                  <div className="sticky-content__feature-icon-wrapper">
                    <div className="logo-white-wrapperr is-red with-shadow">
                      {/* <div className="login-text hidden-mobile">Login</div> */}
                      <img
                        src={require("../images/idea.png")} style={{width : '100%'}}
                      />
                    </div>
                  </div>
                  <h3 className="gradient-class">Generate Creatives</h3>
                  <p class="paragraph">
                  Eliminate creative blocks with Hawk Glide's AI-generated visuals and copy. Create professional-quality content that captivates your audience and stands out on social media platforms.
                  </p>
                </div>
                <div
                  className="sticky-content__feature is-is"
                  ref={(el) => featureRefs.current.push(el)}
                >
                  {/* Content for the second feature */}
                  <div className="sticky-content__feature-icon-wrapper">
                    <div className="logo-white-wrapperr is-red with-shadow">
                      {/* <div className="login-text hidden-mobile">Login</div> */}
                      <img
                        src={require("../images/post.png")} style={{width : '100%'}}
                      />
                    </div>
                  </div>
                  <h3 className="gradient-class">Automated Social Posting</h3>
                  <p class="paragraph">
                  With Hawk Glide, automate your entire social media strategy. From generating creatives and hashtags to posting content automatically, our AI-driven platform handles everything. Sit back and watch your engagement soar effortlessly.
                  </p>
                </div>
                <div
                  className="sticky-content__feature is-is"
                  ref={(el) => featureRefs.current.push(el)}
                >
                  {/* Content for the second feature */}
                  <div className="sticky-content__feature-icon-wrapper">
                    <div className="logo-white-wrapperr is-red with-shadow">
                      {/* <div className="login-text hidden-mobile">Login</div> */}
                      <img
                        src={require("../images/artificial-intelligence.png")} style={{width : '100%'}}
                      />
                    </div>
                  </div>
                  <h3 className="gradient-class">Smartly Trained AI</h3>
                  <p class="paragraph">
                  Let Hawk Glide's smart AI algorithms optimize your marketing strategies. Harness the power of continuous learning and adaptation for more effective campaigns.
                  </p>
                </div>
                <div
                  className="sticky-content__feature is-is"
                  ref={(el) => featureRefs.current.push(el)}
                >
                  {/* Content for the second feature */}
                  <div className="sticky-content__feature-icon-wrapper">
                    <div className="logo-white-wrapperr is-red with-shadow">
                      {/* <div className="login-text hidden-mobile">Login</div> */}
                      <img
                        src={require("../images/insight.png")} style={{width : '100%'}}
                      />
                    </div>
                  </div>
                  <h3 className="gradient-class">View Insights</h3>
                  <p class="paragraph">
                  Gain actionable insights into your social media performance with Hawk Glide's analytics tool. Track key metrics to refine your strategies and maximize your ROI.
                  </p>
                </div>
                <div
                  className="sticky-content__feature is-is"
                  ref={(el) => featureRefs.current.push(el)}
                >
                  {/* Content for the second feature */}
                  <div className="sticky-content__feature-icon-wrapper">
                    <div className="logo-white-wrapperr is-red with-shadow">
                      {/* <div className="login-text hidden-mobile">Login</div> */}
                      <img
                        src={require("../images/social-engagement.png")} style={{width : '100%'}}
                      />
                    </div>
                  </div>
                  <h3 className="gradient-class">Engagements</h3>
                  <p class="paragraph">
                  Drive audience interactions and loyalty with Hawk Glide's engagement features. Foster meaningful connections and increase likes, comments, and shares on your social media posts.
                  </p>
                </div>
                <div
                  className="sticky-content__feature is-is"
                  ref={(el) => featureRefs.current.push(el)}
                >
                  {/* Content for the second feature */}
                  <div className="sticky-content__feature-icon-wrapper">
                    <div className="logo-white-wrapperr is-red with-shadow">
                      {/* <div className="login-text hidden-mobile">Login</div> */}
                      <img
                        src={require("../images/price.png")} style={{width : '100%'}}
                      />
                    </div>
                  </div>
                  <h3 className="gradient-class">Whitelabel</h3>
                  <p class="paragraph">
                  Customize your Hawk Glide experience with our white-label solutions. Offer a branded experience to your clients or stakeholders, reinforcing your brand identity and professionalism.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="content-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="branding-1 title-text mb-3">
                <span className="branding-2 gradient-class ">
                  AI-Powered Banner Creation
                </span>
              </h3>
              <p className="font-22 mb-5">
                Gone are the days of spending hours crafting the perfect social
                media banner. Our AI technology takes the guesswork out of
                banner design, creating eye-catching visuals that capture your
                audience's attention. Simply input your preferences, and let
                Hawk Glide do the rest. From sleek and professional to bold and
                vibrant, our software ensures your banners stand out from the
                crowd.
              </p>
              <Link to={"/pricing"} className="animated-button">
                Start your 30-Day Free Trial
              </Link>
              <p className="pt-2"><span className="gradient-class">30 Days Free Trial.</span> No Credit Card Needed.</p>
            </div>
            <div className="col-md-6">
              <img
                src={require("../images/data-section-1.png")}
                alt="Data section"
                className="pb-2 pt-4 w-100"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>

          <div className="row py-5">
            <div className="col-md-6 display-desktop">
              <img
                src={require("../images/data-section-1.png")}
                alt="Data section"
                className="pb-2 pt-4 w-100"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            <div className="col-md-6">
              <h3 className="branding-1 title-text mb-3">
                <span className="branding-2 gradient-class ">
                  Dynamic Content Generation
                </span>
              </h3>
              <p className="font-22 mb-5">
                Struggling to come up with engaging content for your social
                media posts? Hawk Glide has you covered. Our AI algorithms
                analyze trending topics, audience preferences, and brand
                identity to generate compelling content tailored to your needs.
                Whether it's informative articles, captivating captions, or
                attention-grabbing headlines, our software delivers content that
                resonates with your audience and drives engagement.
              </p>
              <Link to={"/pricing"} className="animated-button">
                Affordable AI-Powered SMM Tool
              </Link>
            </div>
            <div className="col-md-6 display-mobile">
              <img
                src={require("../images/data-section-1.png")}
                alt="Data section"
                className="pb-2 pt-4 w-100"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="branding-1 title-text mb-3">
                <span className="branding-2 gradient-class ">
                  Effortlessly Manage Your Social Media Presence
                </span>
              </h3>
              <p className="font-22 mb-5">
                Why waste time manually scheduling and posting content across
                multiple social media platforms? With Hawk Glide, you can
                streamline your social media workflow with automated posting.
                Our software integrates seamlessly with popular platforms like
                Facebook, Instagram, Twitter, and LinkedIn, allowing you to
                automatically publish content with ease. Spend less time
                managing your social media accounts and more time connecting
                with your audience.
              </p>
              <Link to={"/pricing"} className="animated-button">
                Unlock the Full Potential
              </Link>
            </div>
            <div className="col-md-6">
              <img
                src={require("../images/data-section-1.png")}
                alt="Data section"
                className="pb-2 pt-4 w-100"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </section> */}

      <section className="calling-data py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3 className="mb-3 title-text">
                <span className="branding-2 gradient-class font-weight-bold">
                  Standout From Your Competitors
                </span>{" "}
                and increase your Social Media Presence
              </h3>
              <p className="font-22 mb-5">
                In today's crowded digital landscape, differentiation is key to
                success. With Hawk Glide, you can elevate your brand above the
                competition and expand your social media footprint. Our
                AI-powered platform empowers you to create unique content,
                engage your audience, and build a strong online presence that
                sets you apart.
              </p>
              <Link to={"/pricing"} className="animated-button">
                Get Started for Free
              </Link>
              <Link
                to={"/"}
                className="animated-button-2"
                style={{ marginLeft: 15 }}
              >
                <span>Watch Video</span>
              </Link>
              <p className="pt-2"><span className="gradient-class">30 Days Free Trial.</span> No Credit Card Needed.</p>
            </div>
            <div className="col-md-6">
              <div className="rounded-4 p-5 bg-blue-dark-image">
                <div className="p-4">
                  <h3 className="mb-3 title-text">
                    <span className="branding-2 gradient-class ">
                      Calling all Agencies!
                    </span>
                  </h3>
                  <p className="font-35 mb-2 text-white">
                    Hawk Glide is your ultimate solution for efficient client
                    management.
                  </p>
                  <p className="font-22 mt-3 mb-5 text-white">
                    Say goodbye to juggling multiple accounts and hello to
                    seamless social media strategy execution. With our platform,
                    you'll streamline your workflow, dazzle clients with custom
                    designs, and effortlessly schedule posts. Let Hawk Glide
                    take your agency to new heights.
                  </p>
                  <Link to={"/signup"} className="animated-button-2">
                    <span>Signup now!</span>
                  </Link>
                  <Link
                    to={"/pricing"}
                    className="animated-button-2"
                    style={{ marginLeft: 15 }}
                  >
                    <span>See Pricing</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqs-section py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h3 className="mb-0 title-text">
              <span className="branding-2 gradient-class font-weight-bold">
                Answers to Your Burning Questions
              </span>
            </h3>
            <h3 className="mb-3 title-text">
              Frequently Asked Questions about Hawk Glide
            </h3>
          </div>
          <div className="accordian-react-data">
            <div>
              <Faq />
            </div>
          </div>
        </div>
      </section>

      <section className="callto-section py-5 bg-branding-2">
        <div className="container">
          {/* <div className="row align-items-center justify-content-center"> */}
          {/* Text on the left side */}
          <div className="w-63 m-auto text-center">
            <div className="text-container">
              <h3 className="title-text fs-1 py-2 mb-3 text-white">
                <span className="font-weight-bold ">
                  Powerful AI tool for Social Media Management
                </span>{" "}
                that increases your Social Media Presence
              </h3>
              <div className="links-tab py-2 my-4">
                <Link to={"/pricing"} className="animated-button-2">
                  <span>Start your 30-Day Free Trial</span>
                </Link>
                <Link
                  to={"/pricing"}
                  className="animated-button-2"
                  style={{ marginLeft: 15 }}
                >
                  <span>See Pricing Plans</span>
                </Link>
                
              </div>
              {/* Add any other text or components as needed */}
            </div>
          </div>
        </div>
      </section>
      {/* Add your home page content here */}
    </div>
  );
};

export default Home;
