// Faq.js

import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (

    <section className="callto-section py-5 bg-branding-2">
        <div className="container">
          {/* <div className="row align-items-center justify-content-center"> */}
            {/* Text on the left side */}
            <div className="w-63 m-auto text-center">
              <div className="text-container">
                <h3 className="title-text fs-1 py-2 mb-3 text-white">
                  <span className="font-weight-bold ">
                    Powerful AI tool for Social Media Management
                  </span>{" "}
                  that increases your Social Media Presence
                </h3>
                <div className="links-tab py-2 my-4">
                  <Link
                    to={"/signup"}
                    className="animated-button-2"
                  >
                    <span>Start your 30-Day Free Trial</span>
                  </Link>
                  <Link
                    to={"/pricing"}
                    className="animated-button-2"
                    style={{ marginLeft: 15 }}
                  >
                   <span>See Pricing Plans</span> 
                  </Link>
                </div>
                {/* Add any other text or components as needed */}
              </div>
            </div>

            
        </div>
      </section>

  );
};


export default CallToAction;