// src/SiteRoutes.js
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/home';
import SignIn from './pages/signin';
import SignUp from './pages/signup';
import AboutUS from './pages/about';
import Blog from './pages/blog';
import InnerBlog from './pages/inner-blog';
import Pricing from './pages/pricing';
import FAQ from './pages/faqs';
import TNC from './pages/term-and-condition';
import Checkout from './pages/checkout';
import AppLayout from './AppLayout';
import ForgotPassword from './pages/forgot-password';
import ThankYou from './pages/thankyou';

const SiteRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top on route change
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      {/* Add other routes as needed */}
      <Route
        path="/"
        element={
          <AppLayout>
            <Home />
          </AppLayout>
        }
      />
      <Route
        path="/sign-in"
        element={
          <AppLayout>
            <SignIn />
          </AppLayout>
        }
      />
      <Route
        path="/signup"
        element={
          <AppLayout>
            <SignUp />
          </AppLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <AppLayout>
            <AboutUS />
          </AppLayout>
        }
      />
      <Route
        path="/blog"
        element={
          <AppLayout>
            <Blog />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/blog/the-power-of-ai"
        element={
          <AppLayout>
            <InnerBlog />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/pricing"
        element={
          <AppLayout>
            <Pricing />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/faq"
        element={
          <AppLayout>
            <FAQ />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/terms-and-condition"
        element={
          <AppLayout>
            <TNC />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/forgot-password"
        element={
          <AppLayout>
            <ForgotPassword />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/checkout"
        element={
          <AppLayout>
            <Checkout />
          </AppLayout>
        }
      />
      <Route
        exact
        path="/thankyou"
        element={
          <AppLayout>
            <ThankYou />
          </AppLayout>
        }
      />
      {/* Add more routes here if necessary */}
    </Routes>
  );
};

export default SiteRoutes;
